
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonList,
  IonSelectOption,

  onIonViewDidEnter
 } from '@ionic/vue';


import { defineComponent, ref } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { LoginResult, RegisterParam, ApiError, isApiError } from '@/lib/api-endpoints';
import MInput from '@/components/MInput.vue';
import MSelect from '@/components/MSelect.vue';
import MPassword from '@/components/MPassword.vue';
import { useStore } from '@/store';
import { getEnum, ServerEnum } from '@/lib/server-enums';
import * as yup from 'yup';
import t from '@/translation';
import { useForm } from 'vee-validate';


export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    //IonIcon,
    IonList,
    //IonItem,
    //IonLabel,
    //IonDatetime,
    IonSelectOption,


    MInput,
    MSelect,
    MPassword,
  },
  setup(){

    const store = useStore();
    const router = useRouter();

    const countries = ref([] as ServerEnum[]);

    onIonViewDidEnter(async () => {
      // load country list
      countries.value = await getEnum('countries'); 
      // clear any old user data
      store.dispatch('setUserData', undefined);
    });

    const registerSchema = yup.object({
      firstname: yup.string().required().label(t("First Name")),
      lastname: yup.string().label(t("Last Name")),
      email: yup.string().email().required().label(t("Email")),
      emailConfirmation: yup.string().required(t("Please confirm the email address"))
       .oneOf([yup.ref('email')], t('Email confirmation must match')),
      // eslint-disable-next-line @typescript-eslint/camelcase
      country_code: yup.string().required().label(t("Country")),
      password: yup.string().required().min(6).label(t('Password')),
      passwordConfirmation: yup.string().required(t('Please confirm the password'))
       .oneOf([yup.ref('password')], t('Passwords must match')),
    });

    // Create a form context with the validation schema
    const { values, validate, resetForm } = useForm({
      validationSchema: registerSchema,
    });

    async function onSubmitForm(){
      const result = await validate();
      if (result.valid){
        const formData: RegisterParam = {
          name: values.firstname! /* + " " + values.lastname */,
          email: values.email!,
          // eslint-disable-next-line @typescript-eslint/camelcase
          country_code: values.country_code!,
          password: values.password!,
        };
        
        const userData: ApiError | LoginResult = await store.dispatch('register', formData);
        if (! isApiError(userData)){
          router.replace("/register_wedding");
          window.setTimeout(resetForm, 2000); // delayed so that validation errors are not seen before the page transistions.
        }
      } 
    }

    return {
      countries,
      onSubmitForm,
    }
  }
});
