import countryList from './countries';

export interface ServerEnum {
  id: number;
  tag: string;
  title: string;
  sort: number;
}


const serverEnums: {[key: string]: ServerEnum[]}  = {
  /*
  'countries' : [
    {id: 1, tag: "australia", title: "Australia", sort:	1},
    {id: 4, tag: "new_zealand", title: "New Zealand", sort:	2},
    {id: 2, tag: "united_kingdom", title: "United Kingdom", sort:	3},
    {id: 3, tag: "united_states", title: "United States", sort:	4},
    
  ],
  */

  'meal_types': [
    {id: 0, tag:"sit_down", title: "Sit Down", sort:	1},
    {id: 1, tag:"cocktail", title: "Cocktail", sort:	2},
  ] 
}

// this function is declared async because it
// will likely be replaced with an api-request
// in the future.
export async function getEnum(enumName: string): Promise<ServerEnum[]> {
  
  if( enumName == 'countries' ) {
    const vals = [] as ServerEnum[];
    let id = 1;
    let sort = 10;
    for(const country of countryList){
      
      vals.push({
        id: id,
        tag: country['isoAlpha2'],
        title: country['name'],
        sort: sort,
      });
      id += 1;
      sort += 10;
    }

    return Promise.resolve(vals);

  } else if (!(enumName in serverEnums)){
    throw Error("Invalid server enum '" + enumName + "' requested.");
  } else {
    return Promise.resolve(serverEnums[enumName]);
  }
} 