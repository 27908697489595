import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "ion-text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_m_input = _resolveComponent("m-input")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_m_select = _resolveComponent("m-select")!
  const _component_m_password = _resolveComponent("m-password")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { "default-href": "/login" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Create New Account")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode("form", {
            action: "#",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onSubmitForm()), ["prevent"])),
            onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.onSubmitForm()), ["enter"]))
          }, [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_m_input, {
                  label: _ctx.$t('First Name'),
                  name: "firstname"
                }, null, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Last Name'),
                  name: "lastname"
                }, null, 8, ["label"]),
                _createVNode(_component_m_input, {
                  type: "email",
                  label: _ctx.$t('Email'),
                  name: "email"
                }, null, 8, ["label"]),
                _createVNode(_component_m_input, {
                  type: "email",
                  label: _ctx.$t('Confirm Email'),
                  name: "emailConfirmation"
                }, null, 8, ["label"]),
                _createVNode(_component_m_select, {
                  label: _ctx.$t('Country'),
                  name: "country_code"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: country.tag,
                        value: country.tag
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(country.title)), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_m_password, {
                  label: _ctx.$t('Password'),
                  name: "password",
                  autocomplete: "new-password"
                }, null, 8, ["label"]),
                _createVNode(_component_m_password, {
                  label: _ctx.$t('Confirm Password'),
                  name: "passwordConfirmation"
                }, null, 8, ["label"])
              ]),
              _: 1
            }),
            _createVNode("div", _hoisted_1, [
              _createVNode(_component_ion_button, { type: "submit" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Register')), 1)
                ]),
                _: 1
              })
            ])
          ], 32)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}